import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["notesModal"];

  openNotesModal() {
    let bookPromptId = event.currentTarget.dataset.bookPromptId;
    this.data.set("book-prompt-id", bookPromptId);
    this.notesModalTarget.classList.remove("hidden");
    document.body.classList.add('overflow-hidden')
  }

  closeNotesModal() {
    this.notesModalTarget.classList.add("hidden");
    document.body.classList.remove('overflow-hidden')
  }
}
