// Source: https://stevepolito.design/blog/create-a-nested-form-in-rails-from-scratch
// Adapted using ChatGPT for Book-Specific Reading Challenges

class addFields {
  constructor() {
    this.links = document.querySelectorAll('.add_fields');
    this.iterateLinks();
  }

  iterateLinks() {
    if (this.links.length === 0) return;
    this.links.forEach(link => {
      link.addEventListener('click', e => {
        this.handleClick(link, e);
      });
    });
  }

  handleClick(link, e) {
    if (!link || !e) return;
    e.preventDefault();

    // Generate a new unique ID for the HTML structure
    let newHtmlUniqueId = this.generateUniqueId();

    // Current object_id from the data-id attribute
    let oldObjectId = link.dataset.id;

    // Generate a new object_id (you can adjust the method of generation as needed)
    let newObjectId = this.generateUniqueId();

    // Update the data-fields with new unique IDs
    let newFields = this.updateDataFields(link.dataset.fields, newHtmlUniqueId, oldObjectId, newObjectId);

    // Insert the new fields into the DOM
    link.insertAdjacentHTML('beforebegin', newFields);

    // Update the link's data-id and data-fields attribute for the next use
    link.dataset.id = newObjectId;
    link.dataset.fields = newFields;

    const newFieldSet = link.previousElementSibling;
    const colorInput = newFieldSet.querySelector('input[type="color"]');
    if (colorInput) {
      colorInput.value = this.getRandomColor();
    }

    initializeSelect2OnCustomSegmentTags();
  }

  updateDataFields(fieldsHtml, newHtmlUniqueId, oldObjectId, newObjectId) {
    // Replace HTML unique ID
    let updatedHtml = fieldsHtml.replace(new RegExp('-\\d{13}', 'g'), '-' + newHtmlUniqueId);

    // Replace object_id
    return updatedHtml.replace(new RegExp(oldObjectId, 'g'), newObjectId);
  }

  generateUniqueId() {
    return Math.floor(Math.random() * 9000000000000) + 1000000000000;
  }

  getRandomColor() {
    const colors = ["#E0FCFF", "#BEF8FD", "#87EAF2", "#54D1DB", "#38BEC9", "#2CB1BC", "#14919B", "#0E7C86", "#0A6C74", "#044E54",
      "#E3F8FF", "#B3ECFF", "#81DEFD", "#5ED0FA", "#40C3F7", "#2BB0ED", "#1992D4", "#127FBF", "#0B69A3", "#035388",
      "#FFE3EC", "#FFB8D2", "#FF8CBA", "#F364A2", "#E8368F", "#DA127D", "#BC0A6F", "#A30664", "#870557", "#620042",
      "#FFE3E3", "#FFBDBD", "#FF9B9B", "#F86A6A", "#EF4E4E", "#E12D39", "#CF1124", "#AB091E", "#8A041A", "#610316",
      "#EFFCF6", "#C6F7E2", "#8EEDC7", "#65D6AD", "#3EBD93", "#27AB83", "#199473", "#147D64", "#0C6B58", "#014D40",
      "#FFFBEA", "#FFF3C4", "#FCE588", "#FADB5F", "#F7C948", "#F0B429", "#DE911D", "#CB6E17", "#B44D12", "#8D2B0B"];
    return colors[Math.floor(Math.random() * colors.length)];
  }

}

window.addEventListener('turbo:load', () => new addFields());
