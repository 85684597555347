import { Controller } from "stimulus"
import Quagga from '@ericblade/quagga2';

export default class extends Controller {
  isScanning = false;
  isScanningOwned = false;

  scan() {
    if (typeof AndroidScanner !== 'undefined' && AndroidScanner.requestPermissions) {
      AndroidScanner.requestPermissions(false);
      return;
    }

    this.startScanning();
  }

  startScanning() {
    document.body.classList.add('overflow-hidden');

    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        let selectedDeviceId = null;
        let lastVideoInputDeviceId = null;

        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            lastVideoInputDeviceId = device.deviceId;

            if (device.label.includes("camera2 0, facing back")) {
              selectedDeviceId = device.deviceId;
            }
          }
        });

        if (!selectedDeviceId) {
          selectedDeviceId = lastVideoInputDeviceId;
        }

        this.initializeQuagga(selectedDeviceId);
      })
      .catch(err => {
        console.error('Error enumerating devices:', err);
      });
  }

  initializeQuagga(selectedDeviceId) {
    if (typeof AndroidScanner !== 'undefined') {
      var constraints = { deviceId: selectedDeviceId };
    } else {
      var constraints = { facingMode: "environment" };
    }

    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: document.getElementById("scanner-container"),
        constraints: constraints,
      },
      locator: {
        patchSize: "small",
        halfSample: false
      },
      decoder: {
        readers: ["ean_reader"]
      },
    }, (err) => {
      if (err) {
        this.isScanning = false;
        return
      }
      Quagga.start();
      const track = Quagga.CameraAccess.getActiveTrack();
      if (track && track.getCapabilities && track.applyConstraints) {
        var capabilities = track.getCapabilities();
        if (capabilities.zoom) {
          track.applyConstraints({
            advanced: [{ zoom: capabilities.zoom.max }]
          })
        }
      }
      document.querySelector("video").classList.add("rounded-md", "h-full", "inline");
      var videoElement = document.querySelector('#scanner-container > video');
      if (videoElement) {
        videoElement.classList.add('hidden');
        videoElement.classList.add('w-full', 'h-full', 'm-auto');
        videoElement.classList.remove('hidden');
        const scannerModal = document.getElementById("barcode-scanner-modal");
        scannerModal.classList.remove("hidden");
        document.querySelectorAll(".scanner-frame").forEach(div => div.classList.remove("hidden"));
      }
    });

    Quagga.onDetected((result) => {
      document.querySelectorAll(".scanner-frame").forEach(div => div.classList.add("hidden"));
      document.body.classList.remove('overflow-hidden');
      Quagga.offDetected();
      Quagga.stop();
      var isbn = result.codeResult.code;
      this.searchBook(isbn);
    });
  }

  scanOwned() {
    if (typeof AndroidScanner !== 'undefined' && AndroidScanner.requestPermissions) {
      AndroidScanner.requestPermissions(true);
      return;
    }

    this.startScanningOwned();
  }

  startScanningOwned() {
    document.body.classList.add('overflow-hidden');

    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        let selectedDeviceId = null;
        let lastVideoInputDeviceId = null;

        devices.forEach(device => {
          if (device.kind === 'videoinput') {
            lastVideoInputDeviceId = device.deviceId;

            if (device.label.includes("camera2 0, facing back")) {
              selectedDeviceId = device.deviceId;
            }
          }
        });

        if (!selectedDeviceId) {
          selectedDeviceId = lastVideoInputDeviceId;
        }

        this.initializeQuaggaOwned(selectedDeviceId);
      })
      .catch(err => {
        console.error('Error enumerating devices:', err);
      });
  }


  initializeQuaggaOwned(selectedDeviceId) {
    if (typeof AndroidScanner !== 'undefined') {
      var constraints = { deviceId: selectedDeviceId };
    } else {
      var constraints = { facingMode: "environment" };
    }

    Quagga.init({
      inputStream: {
        name: "Live",
        type: "LiveStream",
        target: document.getElementById("owned-scanner-container"),
        constraints: constraints,
      },
      locator: {
        patchSize: "small",
        halfSample: false
      },
      decoder: {
        readers: ["ean_reader"]
      },
    }, (err) => {
      if (err) {
        this.isScanningOwned = false;
        return
      }
      Quagga.start();
      const track = Quagga.CameraAccess.getActiveTrack();
      if (track && track.getCapabilities && track.applyConstraints) {
        var capabilities = track.getCapabilities();
        if (capabilities.zoom) {
          track.applyConstraints({
            advanced: [{ zoom: capabilities.zoom.max }]
          })
        }
      }
      document.querySelector("video").classList.add("rounded-md", "h-full", "inline");
      var videoElement = document.querySelector('#owned-scanner-container > video');
      if (videoElement) {
        videoElement.classList.add('hidden');
        videoElement.classList.add('w-full', 'h-full', 'm-auto');
        videoElement.classList.remove('hidden');
        const scannerModal = document.getElementById("owned-barcode-scanner-modal");
        scannerModal.classList.remove("hidden");
        document.querySelectorAll(".owned-scanner-frame").forEach(div => div.classList.remove("hidden"));
      }
    });

    Quagga.onDetected((result) => {
      document.querySelectorAll(".owned-scanner-frame").forEach(div => div.classList.add("hidden"));
      document.body.classList.remove('overflow-hidden');
      Quagga.offDetected();
      Quagga.stop();
      document.getElementById("barcode-scanner-icon").classList.remove("hidden");
      var isbn = result.codeResult.code;
      this.searchBookAndMarkAsOwned(isbn);
    });
  }

  searchBook(isbn) {
    if (this.isScanning) return;
    this.isScanning = true;
    fetch('/scan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ isbn: isbn })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response error;');
      }
      return response.json();
    }).then(data => {
      Turbo.visit(data.url);
    }).catch(error => {
      console.error('There has been a problem with your request:', error);
    }).finally(() => {
      this.isScanning = false;
      const scannerModal = document.getElementById("barcode-scanner-modal");
      document.querySelectorAll(".scanner-frame").forEach(div => div.classList.add("hidden"));
      scannerModal.classList.add("hidden")
      document.body.classList.remove('overflow-hidden');
    });
  }

  searchBookAndMarkAsOwned(isbn) {
    if (this.isScanningOwned) return;
    this.isScanningOwned = true;
    fetch('/scan-to-owned', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: JSON.stringify({ isbn: isbn })
    })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
      .catch(error => console.error('Error:', error))
      .finally(() => {
        this.isScanningOwned = false;
        const scannerModal = document.getElementById("owned-barcode-scanner-modal");
        document.querySelectorAll(".owned-scanner-frame").forEach(div => div.classList.add("hidden"));
        scannerModal.classList.add("hidden")
        document.getElementById("barcode-scanner-icon").classList.remove("hidden");
        document.body.classList.remove('overflow-hidden');
      });
  }

  closeScannerModal() {
    document.body.classList.remove('overflow-hidden');
    Quagga.offDetected();
    Quagga.stop();
    this.isScanning = false;
    const scannerModal = document.getElementById("barcode-scanner-modal");
    document.querySelectorAll(".scanner-frame").forEach(div => div.classList.add("hidden"));
    scannerModal.classList.add("hidden")
  }

  closeOwnedScannerModal() {
    document.body.classList.remove('overflow-hidden');
    Quagga.offDetected();
    Quagga.stop();
    this.isScanningOwned = false;
    const scannerModal = document.getElementById("owned-barcode-scanner-modal");
    document.querySelectorAll(".owned-scanner-frame").forEach(div => div.classList.add("hidden"));
    scannerModal.classList.add("hidden")
    document.getElementById("barcode-scanner-icon").classList.remove("hidden");
  }
}
