import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (document.getElementById("select-fave-genres")) {
      if ($("#select-fave-genres").data('select2') == undefined && $("#select-fave-genres").next().hasClass('select2-container')) {
        $("#select-fave-genres").next().remove();
      }

      $("#select-fave-genres").select2({
        width: "100%",
        maximumSelectionLength: 5,
        closeOnSelect: false
      }).val(userFaveGenres());

      $("#select-fave-genres").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });

      function userFaveGenres() {
        $.ajax({
          url: "/user_reading_preferences_fave_genres",
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          success: function(data, status, xhr) {
            $("#select-fave-genres").val(data).trigger('change');
          }
        });
      }
    }

    if (document.getElementById("select-disliked-genres")) {
      if ($("#select-disliked-genres").data('select2') == undefined && $("#select-disliked-genres").next().hasClass('select2-container')) {
        $("#select-disliked-genres").next().remove();
      }

      $("#select-disliked-genres").select2({
        width: "100%",
        closeOnSelect: false
      }).val(userDislikedGenres());

      $("#select-disliked-genres").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });

      function userDislikedGenres() {
        $.ajax({
          url: "/user_reading_preferences_disliked_genres",
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          success: function(data, status, xhr) {
            $("#select-disliked-genres").val(data).trigger('change');
          }
        });
      }
    }
  }
}
