export default class Bridge {
  static disablePurchaseButton() {
    document.getElementById("ios-app-payment-button").innerText = "Purchasing..."
  }

  static enablePurchaseButton() {
    document.getElementById("ios-app-payment-button").innerText = "Buy Plus"
  }

  static showPlusSuccessPage() {
    window.Turbo.visit("/success");
  }
}
