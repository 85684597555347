import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (document.getElementById("find-and-add-publisher")) {
      if ($("#find-and-add-publisher").data('select2') == undefined && $("#find-and-add-publisher").next().hasClass('select2-container')) {
        $("#find-and-add-publisher").next().remove();
      }

      $("#find-and-add-publisher").select2({
        width: "100%",
        placeholder: 'Search for publisher...',
        allowClear: true,
        escapeMarkup: function(markup) {
          return markup;
        },
        ajax: {
          url: '/publishers',
          type: 'GET',
          dataType: 'json',
          processResults: function(data) {
            return {
              results: data.map(function(publisher, i) {
                return {
                  id: publisher.id,
                  text: publisher.name
                }
              })
            }
          },
        },
        templateResult: function(publisher) {
          if (!publisher.id) {
            return publisher.text;
          }
          var $publisher = publisher.text + '<br />' + publisher.id
          return $publisher;
        },
        templateSelection: function(publisher) {
          return publisher.text;
        },
        delay: 500,
        minimumInputLength: 3
      });

      $('#find-and-add-publisher').on('select2:open', function (e) {
        $('.add-publisher-container').remove();
        $(".select2-results:not(:has(a))").append('<div class="add-publisher-container" style="margin-left:10px;margin-right:10px"><hr><p class="mt-2">..or add a new publisher if the one you\'re looking for isn\'t in our database:</p><input id="add-publisher" class="mt-1 block w-full bg-transparent border border-darkerGrey dark:border-lightGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 sm:text-sm" type="text" placeholder="Add a new publisher"></input><br /><div data-controller="publishers"><button data-action="publishers#addPublisher" id="add-publisher-submit" class="cursor-pointer secondary-btn -mt-2 mb-3">Add publisher</button></div></p></div>')
      });

      $("#find-and-add-publisher").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }
  }
}
