import { Controller } from "stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static get targets() { return [ "submit", "clear" ] }

  initialize() {
    this.submit = debounce(this.submit.bind(this), 100)
  }

  connect() {
    this.submitTarget.hidden = true
    this.clearTarget.style.right = "4px"
  }

  submit() {
    this.submitTarget.click()
  }

  viewAll() {
    var searchTerm = $('#search').val();
    window.Turbo.visit('/browse' + '?search_term=' + searchTerm, { action: 'advance' });
  }

  hideValidationMessage(event) {
    event.stopPropagation()
    event.preventDefault()
  }
}
