import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (document.getElementById("find-and-add-series")) {
      if ($("#find-and-add-series").data('select2') == undefined && $("#find-and-add-series").next().hasClass('select2-container')) {
        $("#find-and-add-series").next().remove();
      }

      $("#find-and-add-series").select2({
        width: "100%",
        placeholder: 'Search for series...',
        allowClear: true,
        escapeMarkup: function(markup) {
          return markup;
        },
        ajax: {
          url: '/series',
          type: 'GET',
          dataType: 'json',
          processResults: function(data) {
            return {
              results: data.map(function(series, i) {
                return {
                  id: series.id,
                  text: series.name
                }
              })
            }
          },
        },
        templateResult: function(series) {
          if (!series.id) {
            return series.text;
          }
          var $series = series.text + '<br />' + series.id
          return $series;
        },
        templateSelection: function(series) {
          return series.text;
        },
        delay: 500,
        minimumInputLength: 3
      });

      var relatedBook = document.getElementById("related_book_id");

      if (relatedBook) {
        var bookId = relatedBook.getAttribute("value");
        var seriesSelect = $('#find-and-add-series');
        seriesSelect.val(null).trigger('change');
        $.ajax({
          type: 'GET',
          url: '/series/book/' + bookId
        }).then(function (data) {
          var option = new Option(data.name, data.id, true, true);
          seriesSelect.append(option).trigger('change');

          seriesSelect.trigger({
            type: 'select2:select',
            params: {
              data: data
            }
          });
        });
      }

      $('#find-and-add-series').on('select2:open', function (e) {
        $('.add-series-container').remove();
        $(".select2-results:not(:has(a))").append('<div class="add-series-container" style="margin-left:10px;margin-right:10px"><hr><p class="mt-2">..or add a new series if the one you\'re looking for isn\'t in our database:</p><input id="add-series" class="mt-1 block w-full bg-white dark:bg-darkestGrey border border-darkerGrey dark:border-lightGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 sm:text-sm" type="text" placeholder="Add a new series"></input><br /><div data-controller="series"><button data-action="series#addSeries" id="add-series-submit" class="cursor-pointer secondary-btn -mt-2 mb-3">Add series</button></div></p></div>')
      });

      $("#find-and-add-series").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }
  }
}
