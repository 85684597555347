import { Controller } from "stimulus"
import Combobox from "@github/combobox-nav"

export default class extends Controller {
  static targets = [ "input", "list" ]

  connect() {
    this.inputTarget.addEventListener('keypress', event => this.handleKeyPress(event));
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.submitSearch();
    }
  }

  disconnect() {
    this.combobox?.destroy()
  }

  listTargetConnected() {
    this.start()
  }

  collapseListTarget() {
    this.listTarget.hidden = true
  }

  start() {
    this.combobox?.destroy()
    this.listTarget.hidden = false
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()
    window.clickOutSideBuddyReadsSearchBooks = (element, collapseListTarget) => {
      document.addEventListener('click', (event) => {
        if (!element.contains(event.target)) {
          collapseListTarget();
        }
      });
    };
    window.clickOutSideBuddyReadsSearchBooks(document.querySelector('#buddy-reads-search-books'), () => this.collapseListTarget());
  }

  submit() {
    const searchTerm = this.inputTarget.value
    fetch(`/buddy_reads/book_search?buddy_reads_book_search_term=${encodeURIComponent(searchTerm)}`, {
      method: 'GET',
    })
      .then(response => response.text())
      .then(html => {
        this.element.querySelector('#buddy_reads_book_search_results').innerHTML = html;
      });
  }

  stop() {
    this.combobox?.stop()
  }
}
