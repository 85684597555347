import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "format", "prizeDeliverySection", "deliveryMechanismField", "inAppDeliverySetting", "inAppDeliveryFileUploadField", "inAppDeliveryDigitalAcceptedFileTypes", "inAppDeliveryAudioAcceptedFileTypes", "fileInput"]

  connect() {
    if (this.formatTarget) {
      this.setFileAcceptAttribute();
      const format = this.formatTarget.value;
      if (format === 'digital' || format === 'audio') {
        this.prizeDeliverySectionTarget.classList.remove('hidden');
        this.inAppDeliverySettingTarget.classList.remove('hidden');
      }
    }
  }

  toggleDeliveryOptions() {
    const format = this.formatTarget.value;

    this.hideAllDeliveryOptions();

    if (format === 'print') {
      this.prizeDeliverySectionTarget.classList.remove('hidden');
      this.deliveryMechanismFieldTarget.classList.remove('hidden');
    } else if (format === 'digital' || format === 'audio') {
      this.prizeDeliverySectionTarget.classList.remove('hidden');
      this.inAppDeliverySettingTarget.classList.remove('hidden');
      const inAppDeliveryYesOption = this.inAppDeliverySettingTarget.querySelector('input[type=radio][value=true]');
      inAppDeliveryYesOption.checked = true;
      this.inAppDeliveryFileUploadFieldTarget.classList.remove('hidden');
      if (format === 'digital') {
        this.inAppDeliveryDigitalAcceptedFileTypesTarget.classList.remove('hidden');
      } else {
        this.inAppDeliveryAudioAcceptedFileTypesTarget.classList.remove('hidden');
      }
      this.setFileAcceptAttribute();
    }
  }

  hideAllDeliveryOptions() {
    this.prizeDeliverySectionTarget.classList.add('hidden');
    this.deliveryMechanismFieldTarget.classList.add('hidden');
    this.inAppDeliverySettingTarget.classList.add('hidden');
    this.inAppDeliveryFileUploadFieldTarget.classList.add('hidden');
    this.inAppDeliveryDigitalAcceptedFileTypesTarget.classList.add('hidden');
    this.inAppDeliveryAudioAcceptedFileTypesTarget.classList.add('hidden');
  }

  toggleInAppDeliveryOptions(event) {
    const selectedValue = event.target.value;
    const format = this.formatTarget.value;

    if (selectedValue === 'false') {
      this.inAppDeliveryFileUploadFieldTarget.classList.add('hidden');
      this.inAppDeliveryDigitalAcceptedFileTypesTarget.classList.add('hidden');
      this.inAppDeliveryAudioAcceptedFileTypesTarget.classList.add('hidden');
      this.deliveryMechanismFieldTarget.classList.remove('hidden');
    } else {
      this.inAppDeliveryFileUploadFieldTarget.classList.remove('hidden');
      if (format === 'digital') {
        this.inAppDeliveryDigitalAcceptedFileTypesTarget.classList.remove('hidden');
      } else {
        this.inAppDeliveryAudioAcceptedFileTypesTarget.classList.remove('hidden');
      }
      this.deliveryMechanismFieldTarget.classList.add('hidden');
    }
  }

  setFileAcceptAttribute() {
    const format = this.formatTarget.value;
    const fileInput = this.fileInputTarget;

    let accept;
    switch(format) {
      case 'digital':
        accept = ".pdf,.epub,.azw,.zip";
        break;
      case 'audio':
        accept = ".mp3,.aac,.wav,.m4a,.m4b,.zip";
        break;
      default:
        accept = "";
        break;
    }
    fileInput.setAttribute("accept", accept);
  }

  submitForm() {
    const fileInput = this.fileInputTarget;
    const fileUploadFieldVisible = !this.inAppDeliveryFileUploadFieldTarget.classList.contains('hidden');
    const fileAttached = fileInput.files.length > 0;
    const scanningMessageDiv = this.formTarget.querySelector('#scanning-message');

    if (fileUploadFieldVisible && fileAttached) {
      if (scanningMessageDiv) {
        scanningMessageDiv.textContent = 'Please wait. Performing a virus scan on the uploaded file...';
        scanningMessageDiv.classList.remove('hidden');
      }
    }
  }
}
