import { Controller } from "stimulus"
import Combobox from "@github/combobox-nav"

export default class extends Controller {
  static targets = [ "input", "list" ]

  connect() {
    this.inputTarget.addEventListener('keypress', event => this.handleKeyPress(event));
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.submitSearch();
    }
  }

  disconnect() {
    this.combobox?.destroy()
  }

  listTargetConnected() {
    this.start()
  }

  collapseListTarget() {
    this.listTarget.hidden = true
  }

  start() {
    this.combobox?.destroy()
    this.listTarget.hidden = false
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    var uniqueId = this.inputTarget.id.replace("book-prompt-search-books-input-", "")
    if (document.getElementById(`book-prompt-search-books-input-${uniqueId}`)) {
      setUpClearInputButton(`book-prompt-search-books-input-${uniqueId}`)
    }
    this.combobox.start()
    window.clickOutSideBookPromptSearchBooks = (element, collapseListTarget) => {
      document.addEventListener('click', (event) => {
        if (!element.contains(event.target)) {
          if (this.hasListTarget) {
            collapseListTarget();
          }
        }
      });
    };
    var uniqueId = this.inputTarget.id.replace("book-prompt-search-books-input-", "")
    window.clickOutSideBookPromptSearchBooks(document.querySelector(`#book-prompt-search-books-${uniqueId}`), () => this.collapseListTarget());
  }

  submit() {
    var searchTerm = this.inputTarget.value
    var uniqueId = this.inputTarget.id.replace("book-prompt-search-books-input-", "")
    var readingChallengeId = this.inputTarget.dataset.readingChallengeId;
    fetch(`/reading_challenge_book_prompts/search?book_prompt_search_term=${encodeURIComponent(searchTerm)}&unique_id=${uniqueId}&reading_challenge_id=${readingChallengeId}`, {
      method: 'GET',
    })
      .then(response => response.text())
      .then(html => {
        this.element.querySelector(`#book-prompt-search-results-${uniqueId}`).innerHTML = html;
      });
  }

  stop() {
    this.combobox?.stop()
  }
}
