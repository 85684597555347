import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["customChartAdminMenu"];

  toggleMenu() {
    $('#custom-stats-admin-menu').toggle();
  }

  toggleCustomChartMenu() {
    this.customChartAdminMenuTarget.classList.toggle("hidden");
  }
}
