import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["userAdminMenu", "emojiButton", "reactionsUserListModal", "reactionUserList"];

  toggleMenu() {
    $('#readalong-admin-menu').toggle();
  }

  toggleUserAdminMenu() {
    this.userAdminMenuTarget.classList.toggle("hidden");
  }

  selectReaction(event) {
    const button = event.currentTarget;
    this.setActiveReaction(button);
    this.loadReactionContent(button.dataset.reaction);
  }

  openReactionsUserList() {
    let commentId = event.currentTarget.dataset.commentId;
    this.data.set("comment-id", commentId);
    this.reactionsUserListModalTarget.classList.remove("hidden");
    document.body.classList.add('overflow-hidden')
    this.setActiveReaction(this.emojiButtonTargets[0]);
    this.loadReactionContent(this.emojiButtonTargets[0].dataset.reaction);
  }

  setActiveReaction(button) {
    this.emojiButtonTargets.forEach(btn => btn.classList.remove('bg-darkGrey', 'dark:bg-darkerGrey'));
    button.classList.add('bg-darkGrey', 'dark:bg-darkerGrey');
  }

  loadReactionContent(reaction) {
    const commentId = this.data.get("comment-id");
    const url = `/readalong_forum_comment_reactions?comment_id=${commentId}&emoji=${encodeURIComponent(reaction)}`;

    fetch(url)
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then(html => {
        this.reactionUserListTarget.innerHTML = html;
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
  }


  closeReactionsUserList() {
    this.reactionsUserListModalTarget.classList.add("hidden");
    document.body.classList.remove('overflow-hidden')
  }
}
