import { Controller } from "stimulus"

export default class extends Controller {
  addAuthor() {
    var authorName = $('#add-author').val();
    $.ajax({
      method: "POST",
      url: "/authors",
      data: JSON.stringify({ author: { name: authorName } }),
      contentType: "application/json; charset=utf-8",
      dataType: 'json',
      success: function(data, status, xhr) {
        $('#find-and-add-authors').select2('close');
        var newAuthor = new Option(data.name, data.id, true, true);
        $('#find-and-add-authors').append(newAuthor).trigger('change');
      },
      error: function(data) {
        console.log('Author not created');
      }
    });
  }

  addContributor() {
    var contributorField = $("#latest-contributor-container").children('#add-contributor')
    var contributorName = contributorField.val();
    $.ajax({
      method: "POST",
      url: "/authors",
      data: JSON.stringify({ author: { name: contributorName } }),
      contentType: "application/json; charset=utf-8",
      dataType: 'json',
      success: function(data, status, xhr) {
        document.getElementById('latest-contributor-container').classList.remove('add-contributor-container')
        var uId = document.getElementById('latest-contributor-container').classList[0]
        $('.find-and-add-contributor').select2('close');
        var newContributor = new Option(data.name, data.id, true, true);
        $(".find-and-add-contributor[id=" + uId + "]").append(newContributor).trigger('change');
      },
      error: function(data) {
        console.log('Contributor not created');
      }
    });
  }
}
