import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (document.getElementById("find-and-add-authors")) {
      if ($("#find-and-add-authors").data('select2') == undefined && $("#find-and-add-authors").next().hasClass('select2-container')) {
        $("#find-and-add-authors").next().remove();
      }

      $("#find-and-add-authors").select2({
        width: "100%",
        placeholder: 'Search for author...',
        escapeMarkup: function(markup) {
          return markup;
        },
        ajax: {
          url: '/authors',
          type: 'GET',
          dataType: 'json',
          processResults: function(data) {
            return {
              results: data.map(function(author, i) {
                return {
                  id: author.id,
                  text: author.name
                }
              })
            }
          },
        },
        templateResult: function(author) {
          if (!author.id) {
            return author.text;
          }
          var $author = author.text + '<br />' + author.id.substring(0,5)
          return $author;
        },
        templateSelection: function(author) {
          return author.text;
        },
        delay: 500,
        minimumInputLength: 3
      });

      var relatedBook = document.getElementById("related_book_id");

      if (relatedBook) {
        var bookId = relatedBook.getAttribute("value");
        var authorSelect = $('#find-and-add-authors');
        authorSelect.val(null).trigger('change');
        $.ajax({
          type: 'GET',
          url: '/authors/book/' + bookId
        }).then(function (data) {
          data.forEach(function(author) {
            var option = new Option(author.name, author.id, true, true);
            authorSelect.append(option).trigger('change');

            authorSelect.trigger({
              type: 'select2:select',
              params: {
                data: author
              }
            });
          })
        });
      }

      $('#find-and-add-authors').on('select2:open', function (e) {
        $('.add-author-container').remove();
        $(".select2-results:not(:has(a))").append('<div class="add-author-container" style="margin-left:10px;margin-right:10px"><hr><p class="mt-2 text-sm">..or add a new author if the one you\'re looking for isn\'t in our database:</p><input id="add-author" class="mt-1 block w-full bg-white dark:bg-darkestGrey border border-darkerGrey dark:border-lightGrey rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-cyan-600 dark:focus:ring-cyan-400 focus:border-cyan-700 dark:focus:border-cyan-500 sm:text-sm" type="text" placeholder="Add a new author"></input><br /><div data-controller="authors"><button data-action="authors#addAuthor" id="add-author-submit" class="cursor-pointer inline-flex secondary-btn -mt-2 mb-3" >Add author</button></div></p></div>')
      });

      $("#find-and-add-authors").on('select2:select', function(e){
        var id = e.params.data.id;
        var option = $(e.target).children('[value='+id+']');
        option.detach();
        $(e.target).append(option).change();
      });
    }
  }
}
