import { Controller } from "stimulus"
import Combobox from "@github/combobox-nav"

export default class extends Controller {
  static targets = [ "input", "list" ]

  connect() {
    this.inputTarget.addEventListener('keypress', event => this.handleKeyPress(event));
    this.number = this.element.id.match(/\d+/)[0];
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.submitSearch();
    }
  }

  disconnect() {
    this.combobox?.destroy()
  }

  listTargetConnected() {
    this.start()
  }

  collapseListTarget() {
    if (this.hasListTarget) {
      this.listTarget.hidden = true
    }
  }

  start() {
    this.combobox?.destroy()
    this.listTarget.hidden = false
    this.combobox = new Combobox(this.inputTarget, this.listTarget)
    this.combobox.start()

    const searchInputId = `#poll_option_search_books_${this.number}`;
    const collapseFunction = () => this.collapseListTarget();
    window.clickOutSidePollOptionBookSearchBooks = (element, collapseListTarget) => {
      document.addEventListener('click', (event) => {
        if (!element.contains(event.target)) {
          collapseListTarget();
        }
      });
    };
    window.clickOutSidePollOptionBookSearchBooks(document.querySelector(searchInputId), collapseFunction);
  }

  submit() {
    const searchTerm = this.inputTarget.value
    fetch(`/poll_options/book_search?poll_option_book_search_term=${encodeURIComponent(searchTerm)}&number=${this.number}`, {
      method: 'GET',
    })
      .then(response => response.text())
      .then(html => {
        this.element.querySelector(`#poll_option_book_search_results_${this.number}`).innerHTML = html;
      });
  }

  stop() {
    this.combobox?.stop()
  }
}
