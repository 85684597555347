import { Controller } from "stimulus"

document.addEventListener("turbo:before-cache", () => $('#select-country').each(function() {
  var el = $(this);
  var selected = el.val();
  el.find('option[value="' + selected + '"]').attr('selected', true);
}));

export default class extends Controller {
  connect() {
    if (document.getElementById("select-country")) {
      if ($("#select-country").data('select2') == undefined && $("#select-country").next().hasClass('select2-container')) {
        $('#select-country').next().remove();
      }
    }
  }
}
